import { useDraggable } from "@dnd-kit/core";
import { CSSProperties } from "react";

interface DraggableAgentProps {
    agent: any;
    containerId: string;
    activeId?: string | null
}


const DraggableAgent: React.FC<DraggableAgentProps> = ({agent, containerId, activeId}) => {
    const { attributes, listeners, setNodeRef, transform , isDragging} = useDraggable
    ({
        id: agent.AGENTID,
        data: {
            containerId: containerId,
            agentData: agent 
        }
    });

    const isCurrentDraggedItem = activeId === String(agent.AGENTID); // Convert PROMPTID to string

    const containerStyle: CSSProperties = {
        zIndex: isDragging ? 1000 : 'auto',
        opacity: isDragging || isCurrentDraggedItem ? 0 : 1, // Hide if being dragged
        pointerEvents: isDragging ? 'none' : 'auto',
        touchAction: isDragging ? 'none' : 'auto', // Prevent scrolling when dragging
    };

    return (
        <div 
        className="d-flex justify-content-between align-items-center mb-2 " 
        style={containerStyle}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        data-container-id={containerId}
        >
            <p className="m-0 bc-draggable-item">{agent?.AGENTNAME}</p>
        </div>
    )
}

export default DraggableAgent;