import Card from "../card/card";
import { useGetPromptsMutation } from "../../../api-integration/secure/prompts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fullPageLoader, updateAlertMessage, updateLibraryType } from "../../../api-integration/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Deploy from '../../../assets/icons/deploy.svg';
import TooltipComponent from "../bootstrap-component/tooltip-component";
import { getPageByURL, getPageSettingValue, getTranslatedText } from "../../../util/util";
import useManageUserPageSettings from "../../../hooks/useManageUserPageSettings";
import usePageTranslation from "../../../hooks/usePageTranslation";
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";

type SortCriteria = "newest_date" | "updated_date" | "alphabetical";

const PromptList = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PageTranslation = usePageTranslation();
  const location = useLocation();
  const pageName = getPageByURL(location.pathname);
  const { register } = useForm();
  const { user, LibraryTypeState, PageMemory, TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const LibraryType = localStorage.getItem('LibraryType') || LibraryTypeState || 'personal';
  const [libSwitch, setLibSwitch] = useState({switchVal: 0});
  const [promptCount, setPromptCount] = useState(0);
  const [promptOriginalData, setPromptOriginalData] = useState([]);
  
  const [getPromptsAPI, { data: prompts, isLoading, isSuccess, isError }] 
  = useGetPromptsMutation();
  
  const {manageUserPageSettingsAPI, manageUserPageSettingsData, isManageUserPageSettingsError, manageUserPageSettingsError, } 
  = useManageUserPageSettings();

  
  const [sortingData, setSortingData] = useState<sortingData[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>("alphabetical");
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const [localIncludeRemovedPrompts, setLocalIncludeRemovedPrompts] = useState(0);

  const handleToggle = (param: SortCriteria) => {
    setSortCriteria(param);
    handleSaveSettings('promptSort', param+"-"+!isToggled); //set this before changing the value of isToggled
    setIsToggled(!isToggled);
  };
  const handleSaveSettings = async (key:string, value:string) => {
    try {
      await manageUserPageSettingsAPI({ 
        "pageCode": pageName,
        "userPageSettings": [
          {
            "pageSettingKey": key,
            "pageSettingValue": value
          }
        ]
      });
    } catch (error) {
      dispatch(updateAlertMessage({ status: 'error', message: error }));
    }
  };

  useEffect(() => {
    if (isManageUserPageSettingsError || manageUserPageSettingsError) {
      dispatch(updateAlertMessage({ status: 'error', message: manageUserPageSettingsData.message }));
    }
  }, [isManageUserPageSettingsError, manageUserPageSettingsError]);

  useEffect(() => {
    //get promptSort from page settings
    const promptSortValue = getPageSettingValue(PageMemory, 'promptSort');
    if(promptSortValue){
      setSortCriteria(promptSortValue?.split('-')[0] as SortCriteria);
      setIsToggled(promptSortValue?.split('-')[1] == 'true' ? true : false);
    }
    //get libraryTypeFilter from page settings
    const libraryTypeFilterValue = getPageSettingValue(PageMemory, 'libraryTypeFilter');
    if(libraryTypeFilterValue === 'personal' ){
      handleLibraryTypeChange(0);
    }
    else if(libraryTypeFilterValue === 'org'){
      handleLibraryTypeChange(1);
    }
    //get promptStatusFilter from page settings
    const promptStatusFilterValue = getPageSettingValue(PageMemory, 'promptStatusFilter');
    if(promptStatusFilterValue){
      setLocalIncludeRemovedPrompts(Number(promptStatusFilterValue));
    }
  }, [PageMemory]);

  //SORTING PROMPTS
  type sortingData = {
    CREATEDON: string;
    UPDATEDON: string;
    PROMPTNAME: string;
  };

  useEffect(() => {
    setLibSwitch({ switchVal: LibraryType === 'org' ? 1 : 0});
    localStorage.setItem('LibraryType', LibraryType);
    dispatch(updateLibraryType(LibraryType));
  }, [LibraryType]);
  

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(fullPageLoader(false));
    }
    if (isError) {
      dispatch(updateAlertMessage({ status: 'error', message: prompts?.message }));
    }
    if (isSuccess) {
      if (prompts?.success == true) {
        if (prompts?.promptDetail) {
          setFilteredData(prompts?.promptDetail);
          setPromptOriginalData(prompts?.promptDetail);
          setPromptCount(prompts.promptDetail.length);
        }
      }
      // if (prompts?.success == false) {
      //   dispatch(updateAlertMessage({ status: 'error', message: prompts?.message }));
      // }
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (prompts?.promptDetail) {
      setFilteredData(prompts.promptDetail);
    }
    else {
      setFilteredData([]);
    }
  }, [prompts?.promptDetail]);

  useEffect(() => {
    let filteredData = prompts?.promptDetail || [];
    if (searchQuery !== '') {
      filteredData = filteredData.filter((item: any) =>
        item.PROMPTNAME && item.PROMPTNAME.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }        
    setFilteredData(filteredData);
  }, [searchQuery, props?.solutionsList]);

  useEffect(() => {
    dispatch(fullPageLoader(true));
    getPromptsAPI({
      libraryType: LibraryType,
      includeRemovedPrompts: localIncludeRemovedPrompts, 
      "Page": getPageByURL(location.pathname) || 'home',
      "requestMode":getPageByURL(location.pathname) == "io" ? "execute" : "manage"
    });
    if(props?.reloadPromptList){
      props?.setReloadPromptList(false);
    }
  }, [LibraryType, localIncludeRemovedPrompts, location.pathname, props?.reloadPromptList]);

  const selectAccType = (e: any) => {
    const selectedValue = e.target.value;
    props.setIsLibraryTypeChanged(!props.isLibraryTypeChanged);
    setLibSwitch(prevVal => ({
      ...prevVal,
      switchVal: selectedValue
    }));

    if (selectedValue === 1) {
      // Update State to set library type to 'org' bcoz CC deduction for prompts listed from org library will be from org account
      localStorage.setItem('LibraryType', 'org');
      dispatch(updateLibraryType('org'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'org');
      }
    } 
    else if (selectedValue === 0) {
      // Update State to set library type to 'personal' bcoz CC deduction for prompts listed in personal library will be from personal account
      localStorage.setItem('LibraryType', 'personal');
      dispatch(updateLibraryType('personal'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'personal');
      }
    }
  };

  const handleLibraryTypeChange = (selectedValue:number) => {
    //it sets false due to when library changed by memory basis, appearence of execution tile does not effects
    props.setIsLibraryTypeChanged(false);
    setLibSwitch(prevVal => ({
      ...prevVal,
      switchVal: selectedValue
    }));

    if (selectedValue === 1) {
      // Update State to set library type to 'org' bcoz CC deduction for prompts listed from org library will be from org account
      localStorage.setItem('LibraryType', 'org');
      dispatch(updateLibraryType('org'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'org');
      }
    } 
    else if (selectedValue === 0) {
      // Update State to set library type to 'personal' bcoz CC deduction for prompts listed in personal library will be from personal account
      localStorage.setItem('LibraryType', 'personal');
      dispatch(updateLibraryType('personal'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'personal');
      }
    }
  }

  const handleDisplayRemovePrompt = (e: any) => {
    const selectedValue = e.target.value;
    setLocalIncludeRemovedPrompts(Number(selectedValue));
  };

  useEffect(() => {
    if (isSuccess) {
      if(filteredData?.length === 0){
        setSortingData([]);
        return;
      }
      const sortedData = [...filteredData].sort((a, b) => {
        switch (sortCriteria) {
          case "newest_date":
            if (isToggled) {
              return (
                new Date(a.CREATEDON).getTime() - new Date(b.CREATEDON).getTime()
                );
            } else {
              return (
                new Date(b.CREATEDON).getTime() - new Date(a.CREATEDON).getTime()
              );
            }
          case "updated_date":
            if (isToggled) {
              return (
                new Date(a.UPDATEDON).getTime() - new Date(b.UPDATEDON).getTime()
              );
            } else {
              return (
                new Date(b.UPDATEDON).getTime() - new Date(a.UPDATEDON).getTime()
              );
            }
          case "alphabetical":
            const nameA = a?.PROMPTNAME || '';
            const nameB = b?.PROMPTNAME || '';
            return isToggled ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
          default:
            return 0;
        }
      });
      setSortingData(sortedData);
    }
  }, [sortCriteria, isToggled, promptOriginalData, filteredData]);

  return (
    <Card id={props?.cardId} 
      cardGlow={isSuccess && prompts && (!prompts.promptDetail || (prompts.promptDetail && promptCount < 3)) ? true : false} 
      logo={true} cardHeightClass="h-100" 
      titleType={1} title="myPromptList.card.title" 
      help={true} Feedback={true} 
      helpTitle="myPromptList.cardHelp.title"
      helpContent="myPromptList.cardHelp.content"
    >
      {isLoggedIn && user?.accountType === 'corp' &&
      (  
      <div className="row">
        <div className="col-md-12 mb-1">
            <input 
              type="range" 
              className="form-range bc-range" 
              {...register('libType')} 
              min="0" max="1" step="1" 
              value={libSwitch.switchVal} 
              id="libType" 
              onChange={selectAccType} 
            />
            <div className="d-flex justify-content-between">
              <TooltipComponent title={getTranslatedText("personal.tooltip", PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText("personal.text", PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="personal.text" translationType='text'/>
                  <EditTranslationIcon translationKey="personal.tooltip" translationType='tooltip' />
                </>
              )}
              <TooltipComponent title={getTranslatedText("business.tooltip", PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText("business.text", PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="business.text" translationType='text'/>
                  <EditTranslationIcon translationKey="business.tooltip" translationType='tooltip' />
                </>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-1">
            <input className="form-range bc-range" 
              type="range"
              min="0" max="1" step="1"
              value={localIncludeRemovedPrompts}
              onChange={handleDisplayRemovePrompt}
            />
            <div className="d-flex justify-content-between">
              <TooltipComponent title={getTranslatedText('activePrompts.tooltip', PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText('active.text', PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="active.text" translationType='text'/>
                  <EditTranslationIcon translationKey="activePrompts.tooltip" translationType='tooltip' />
                </>
              )}
              <TooltipComponent title={getTranslatedText('removedPrompts.tooltip', PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText('removedPrompts.text', PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="removedPrompts.text" translationType='text'/>
                  <EditTranslationIcon translationKey="removedPrompts.tooltip" translationType='tooltip' />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {isSuccess && sortingData && sortingData.length > 0 &&
        <h6 className="mb-1 d-flex justify-content-evenly">
          <TooltipComponent title={getTranslatedText('sortByAlphabetical.tooltip', PageTranslation)}>
            <span className="cursor-pointer" onClick={() => handleToggle("alphabetical")}>
              <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByAlphabetical.text', PageTranslation)}} />
            </span>
          </TooltipComponent>
          {TranslationEditFlag && (
            <>
              <EditTranslationIcon translationKey="sortByAlphabetical.text" translationType='text'/>
              <EditTranslationIcon translationKey="sortByAlphabetical.tooltip" translationType='tooltip' />
            </>
          )}
          &nbsp; | &nbsp;
          <TooltipComponent title={getTranslatedText('sortByNewest.tooltip', PageTranslation)}>
            <span className="cursor-pointer" onClick={() => handleToggle("newest_date")}>
              <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByNewest.text', PageTranslation)}} />
            </span>
          </TooltipComponent>
          {TranslationEditFlag && (
            <>
              <EditTranslationIcon translationKey="sortByNewest.text" translationType='text'/>
              <EditTranslationIcon translationKey="sortByNewest.tooltip" translationType='tooltip' />
            </>
          )}
          &nbsp; | &nbsp;
          <TooltipComponent title={getTranslatedText('sortByUpdatedOn.tooltip', PageTranslation)}>
            <span className="cursor-pointer" onClick={() => handleToggle("updated_date")}>
              <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByUpdatedOn.text', PageTranslation)}} />
            </span>
          </TooltipComponent>
          {TranslationEditFlag && (
            <>
              <EditTranslationIcon translationKey="sortByUpdatedOn.text" translationType='text'/>
              <EditTranslationIcon translationKey="sortByUpdatedOn.tooltip" translationType='tooltip' />
            </>
          )}
        </h6>
      }
      {isSuccess && promptOriginalData && promptOriginalData.length > 0 && (
        <div className="mb-1">
          <input
            className='form-control'
            type="text"
            placeholder={getTranslatedText('promptSearch.placeholder', PageTranslation)}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {TranslationEditFlag && <EditTranslationIcon translationKey='promptSearch.placeholder' translationType='placeholder'/>}
        </div>
      )
      }
      <div className="overflow-auto scrollbar h-35vh">
         {isLoading 
        ? (
          <div className='text-center'>
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                  <span className="visually-hidden">Loading…</span>
              </div>
          </div>
        ) 
        : isSuccess && sortingData && sortingData.length > 0  ?
        (sortingData.map((prompt: any, index: number) => (
            <h6 
              className="cursor-pointer mb-3" 
              key={index} 
              onClick={() => {
                props.onLibraryTypeChange();
                if (props?.actionURL) {
                  navigate(`${props?.actionURL}/${prompt?.GPTBLUEPROMPTID}`);
                } else {
                  //navigate(`/app/io/${prompt.URLCODE}`);
                  navigate(`/app/assetIo/${prompt.URLCODE}&assetType=prompt`);
                }
              }}
            >
              <div className="row g-0">
                <div className="col-auto">
                  <TooltipComponent title={prompt?.promptLibDeployment?.Description}>
                    <img src={Deploy} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='pe-1 h-1 cursor-pointer'/>
                  </TooltipComponent> 
                </div>
                <div className="col">
                  <TooltipComponent title={prompt?.PROMPTDESCRIPTION ? prompt?.PROMPTDESCRIPTION : getTranslatedText('promptDescriptionNotAvailable.message', PageTranslation)}>  
                    {prompt?.PROMPTNAME ? prompt?.PROMPTNAME : '-'}
                  </TooltipComponent>
                </div>
              </div>
            </h6>
          ))
        ) 
        : 
        (
          <>
          <p dangerouslySetInnerHTML={{ __html: LibraryType === 'personal' ? getTranslatedText('personalLibraryEmpty.message', PageTranslation) : getTranslatedText('orgLibraryEmpty.message', PageTranslation) }}></p>
          {TranslationEditFlag && (
            <EditTranslationIcon translationKey={LibraryType === 'personal' ? "personalLibraryEmpty.message" : "orgLibraryEmpty.message"} translationType='text'/>
          )}
          </>
        )
        }
      </div>
    </Card>
  )
};
export default PromptList;
