import React, { useEffect } from 'react';
import { getTranslatedText, initializeModal } from '../../../util/util';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import usePageTranslation from '../../../hooks/usePageTranslation';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';
import { useSelector } from 'react-redux';

interface SendEmailProps {
    id: string;
    onSendEmail: () => void;
    emailError: boolean;
    setEmailError: (error: boolean) => void;
    userEmail: string;
    setUserEmail: (comment: string) => void;
    emailPattern?: RegExp;
}

const SendEmailModal: React.FC<SendEmailProps> = ({ id, onSendEmail, emailError, setEmailError, userEmail,setUserEmail, emailPattern  }) => {
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id, resetModalValues);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id, resetModalValues);
        };
    }, [id]);

    const resetModalValues = () => {
        setEmailError(false);
        setUserEmail("");
    }

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`${id}Label`}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('email.text',PageTranslation)}} />
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey="email.text" translationType='text'/>
                                <EditTranslationIcon translationKey="email.placeholder" translationType='placeholder'/>
                            </>
                        )}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <input type="email" 
                        className={`form-control form-control-lg ${emailError ? 'is-invalid' : ''}`} 
                        value={userEmail}
                        placeholder={getTranslatedText('email.placeholder',PageTranslation)}
                        onChange={(e: any) => {
                            setUserEmail(e.target.value);
                            if (e.target.value.length > 0 && e.target.value.match(emailPattern) !== null && e.target.value.length < 250) {
                                setEmailError(false);
                            }
                            else{
                                setEmailError(true);
                            }
                        }} 
                    />
                    {emailError && 
                        <div className="invalid-feedback">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('email.invalid.message',PageTranslation)}} />
                            {TranslationEditFlag && <EditTranslationIcon translationKey="email.invalid.message" translationType='text'/>}
                        </div>
                    }
                </div>
                <div className="modal-footer">
                    <TooltipComponent title={getTranslatedText('close.tooltip',PageTranslation)}>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('close.text',PageTranslation)}} />
                    </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey="close.text" translationType='text'/>
                            <EditTranslationIcon translationKey="close.tooltip" translationType='tooltip'/>
                        </>
                    )}
                    <TooltipComponent title={getTranslatedText('send.tooltip',PageTranslation)}>
                    <button type="button" className="btn btn-primary"
                        onClick={onSendEmail}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('send.text',PageTranslation)}} />
                    </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey="send.text" translationType='text'/>
                            <EditTranslationIcon translationKey="send.tooltip" translationType='tooltip'/>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
};

export default SendEmailModal;