import React, { useEffect, useState } from 'react';
import StarRating from '../secure/star-rating/star-rating';
import Comment from '../../assets/icons/comment.svg';
import Email from '../../assets/icons/email.svg';
import Tab from '../../assets/icons/tab.svg';
import EditTranslationIcon from '../common/edit-translation-icon/EditTranslationIcon';
import TooltipComponent from '../common/bootstrap-component/tooltip-component';
import { getTranslatedText } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { fullPageLoader, updateAlertMessage } from '../../api-integration/commonSlice';
import { useGeneratePdfMutation, useSendAIResponseEmailMutation } from '../../api-integration/public/common';


interface AIResponseActionIconsProps {
    paramAssetType: string;
    assetId: number | undefined;
    assetName: string | undefined;
    commentModalId: string;
    assetAuthorName: string | undefined;
    isShareIconVisible: boolean;
    questionWithResponse: any;
    responseShareUrl: string | undefined;
    AIResponseData:any;
    userFirstPromptId: string | undefined;
    ratings: number;
    setRatings: (rating: number) => void;
    setTempRating: (rating: number) => void;
}

const AIResponseActionIcons: React.FC<AIResponseActionIconsProps> = (props) => {
    const PageTranslation = usePageTranslation();
    const dispatch = useDispatch();
    const MAX_RATING = 7;
    const {  TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
    //State for default rating
    // const [ratings, setRatings] = useState<number>(0);

    const [generatePdfAPI, { data: pdfResponse, isLoading: isPdfLoading, isSuccess: isPdfSuccess, isError: isPdfError, error: pdfError }] = 
    useGeneratePdfMutation();

    const [SendAIResponseEmailAPI, { data: sendAIResponseEmailResponse, isLoading: isSendLoading, isSuccess: isSendSuccess, isError: isSendError, error: sendError }] =
    useSendAIResponseEmailMutation();

    const handleTempRating = (rating: number) => {
        props?.setTempRating(rating);
    };

    useEffect(() => {
        if (props?.AIResponseData === '') {
            props?.setRatings(0);
        }
    }, [props?.AIResponseData]);

    const sendEmail = () => {
        dispatch(fullPageLoader(true));
        let user = JSON.parse(localStorage.getItem('user') as string) || {};
        SendAIResponseEmailAPI({ 
            GPTBlueUserPrompt: props?.assetName, 
            GPTBlueUserEmail: user?.userEmail, 
            userPromptId: props?.userFirstPromptId, 
            GPTBlueResponse: props?.AIResponseData 
        })
    };

    const generatePdf = () => {
        dispatch(fullPageLoader(true));
        generatePdfAPI({ 
            "promptResponseId": props?.userFirstPromptId, 
            "promptResponse": props?.AIResponseData 
        });
    };

    useEffect(() => {
        if (pdfResponse?.success == true) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'success', message: pdfResponse?.message }))
            var file_path = pdfResponse?.pdfURL;
            var a = document.createElement('A') as any;
            a.href = file_path;
            //  This will open up the pdf in new page. 
            a.target = '_blank';
            a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else if ((pdfResponse?.success == false)) {
            dispatch(updateAlertMessage({ status: 'error', message: pdfResponse?.message }))
            dispatch(fullPageLoader(false));
        }
        if (isPdfError || pdfError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: pdfResponse?.message }))
        }
    }, [isPdfSuccess, isPdfError, pdfResponse, pdfError]);

    useEffect(() => {
        if(isSendSuccess){
            if (sendAIResponseEmailResponse?.success == true) {
                dispatch(updateAlertMessage({ status: 'success', message: sendAIResponseEmailResponse?.message }))
                dispatch(fullPageLoader(false));
            }
            else if ((sendAIResponseEmailResponse?.success == false)) {
                dispatch(updateAlertMessage({ status: 'error', message: sendAIResponseEmailResponse?.message }))
                dispatch(fullPageLoader(false));
            }
        }
        if (isSendError || sendError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: sendAIResponseEmailResponse?.message }))
        }
    }, [isSendSuccess, isSendError]);

    return (
        <>
        {/* Star Rating Section */}
        <div className="d-inline-block text-center px-2">
            <div className="mb-1">
                {getTranslatedText('rate.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="rate.text" translationType="text" />
                )}
            </div>
            <div className="d-inline-block">
                <StarRating
                    maxRating = {MAX_RATING}
                    defaultRating = {props?.ratings}
                    onTempRating = {handleTempRating}
                    messages = {[
                        { color: 'var(--bs-gray)', tooltip: getTranslatedText('ratingStar1.tooltip', PageTranslation) },
                        { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar2.tooltip', PageTranslation) },
                        { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar3.tooltip', PageTranslation) },
                        { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar4.tooltip', PageTranslation) },
                        { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar5.tooltip', PageTranslation) },
                        { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar6.tooltip', PageTranslation) },
                        { color: 'var(--bs-secondary)', tooltip: getTranslatedText('ratingStar7.tooltip', PageTranslation)}
                    ]}
                />
                {TranslationEditFlag && (
                <>
                <EditTranslationIcon translationKey="ratingStar1.tooltip" translationType="tooltip" />
                <EditTranslationIcon translationKey="ratingStar2.tooltip" translationType="tooltip" />
                <EditTranslationIcon translationKey="ratingStar3.tooltip" translationType="tooltip" />
                <EditTranslationIcon translationKey="ratingStar4.tooltip" translationType="tooltip" />
                <EditTranslationIcon translationKey="ratingStar5.tooltip" translationType="tooltip" />
                <EditTranslationIcon translationKey="ratingStar6.tooltip" translationType="tooltip" />
                <EditTranslationIcon translationKey="ratingStar7.tooltip" translationType="tooltip" />
                </>
                )}
            </div>
        </div> 
        {/* Comment Section */}
        <div className="d-inline-block text-center px-2">
            <div className="mb-1">
                {getTranslatedText('comment.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="comment.text" translationType="text" />
                )}
            </div>
            <div className="d-inline-block">
                <TooltipComponent title={getTranslatedText('ioResponseComment.tooltip', PageTranslation)} >
                    <img    
                        src={Comment} 
                        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                        className='h-1-5 cursor-pointer' 
                        data-bs-toggle="modal"
                        data-bs-target={props?.commentModalId}
                    />
                </TooltipComponent>
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="ioResponseComment.tooltip" translationType="tooltip" />
                )}
            </div>
        </div>
        {/* Email Section */}
        <div className="d-inline-block text-center px-2">
            <div className="mb-1">
                {getTranslatedText('email.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="email.text" translationType="text" />
                )}
            </div>
            <div className="d-inline-block">
                <TooltipComponent title={getTranslatedText('ioResponseEmail.tooltip', PageTranslation)} >
                    <img 
                        src={Email} 
                        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                        className='h-1-5 cursor-pointer' 
                        data-bs-toggle={isLoggedIn ? undefined : "modal"} 
                        data-bs-target={isLoggedIn ? undefined : "#sendEmailModalId"} 
                        onClick={isLoggedIn ? sendEmail : undefined}
                    />
                </TooltipComponent>
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="ioResponseEmail.tooltip" translationType="tooltip" />
                )}
            </div>
        </div>
        {/* PDF Section */}
        <div className="d-inline-block text-center px-2">
            <div className="mb-1">
                {getTranslatedText('pdf.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="pdf.text" translationType="text" />
                )}
            </div>
            <div className="d-inline-block">
                <TooltipComponent title={getTranslatedText('ioResponsePdf.tooltip', PageTranslation)}  >
                    <img 
                        src={Tab} 
                        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                        className='h-1-5 cursor-pointer' 
                        onClick={generatePdf} 
                    />
                </TooltipComponent>
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="ioResponsePdf.tooltip" translationType="tooltip" />
                )}
            </div>
        </div>
        
    </>
    );
};

export default AIResponseActionIcons;