import './App.scss';
import Header from './components/common/header/header';
import Footer from './components/common/footer/footer';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import AuthGuard from './guards/auth.guard';
import NonAuthGuard from './guards/non-auth.guard';
import AskGPT from './pages/common/askgpt/askgpt';
import { useExtendSessionMutation } from './api-integration/public/common';
import { fullPageLoader, updateAlertMessage, updateIsSessionExpired, updateLogin, updateLogout, updateReloadPageAfterSessionExpired, updateUser } from './api-integration/commonSlice';
import { clearCookies, getTranslatedText } from './util/util';
import { useValidateAuthTokenMutation } from './api-integration/secure/secure';
import { Referral } from './components/common/referral-mapping/referral-mapping';
import ExchangeList from './pages/common/exchange-list/exchange-list';
import usePageTranslation from './hooks/usePageTranslation';
import useInitializePopovers from './components/common/bootstrap-component/initializePopovers';
import AssetIO from './pages/secure/asset-io';

const Login = React.lazy(() => import('./pages/public/login/login'));
const Forgotpwd = React.lazy(() => import('./pages/public/login/forgot-pwd'));
const ExternalGateway = React.lazy(() => import('./pages/public/external-gateway/external-gateway'));

const Secure = React.lazy(() => import('./pages/secure/secure'));
const AppHome = React.lazy(() => import("./pages/common/app-home/app-home"));
const Alert = React.lazy(() => import('./components/common/alert/alert'));
const UnsubscribeUser = React.lazy(() => import("./pages/public/unsubscribe-user/unsubscribe-user"))
const EmbedPrompt = React.lazy(() => import('./pages/public/embed/prompt'));
const EmbedLibrary = React.lazy(() => import('./pages/public/embed/library'));
const ExchangeHome = React.lazy(() => import('./pages/common/exchange-home/exchange-home'));

const App = () => {
  const fullPageLoaderSelector = useSelector((state: any) => state.commonSlice.fullPageLoader);
  const { isSessionExpired, reloadPageAfterSessionExpired, isRefreshTokenExpired } = useSelector((state: any) => state.commonSlice);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') as string);
  //const [, forceUpdate] = useReducer(x => x + 1, 0);
  const PageTranslation = usePageTranslation();
  useInitializePopovers();
  const dispatch = useDispatch();
  const [validateAuthToken] = useValidateAuthTokenMutation();

  // useEffect(() => {
  //   // Retrieve the isLoggedIn state from localStorage on component mount
  //   const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  //   isLoggedIn ? dispatch(updateLogin()) : dispatch(updateLogout());
  // }, [dispatch]);

  // useEffect(() => {
  //   //console.log('isLoggedIn in App.Tsx', isLoggedIn);
  //   // Persist the isLoggedIn state in localStorage whenever it changes
  //   localStorage.setItem('isLoggedIn', String(isLoggedIn));
  // }, [isLoggedIn]);

  useEffect(() => {
    if(isLoggedIn) {
      dispatch(updateReloadPageAfterSessionExpired(false));
      const intervalId = setInterval(() => {
        validateAuthToken({});
      }, 30 * 60 * 1000); // 30 minutes in milliseconds-30 * 60 * 1000

      // Clean up function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [dispatch, validateAuthToken, isLoggedIn]);

  useEffect(() => {
    //const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') as string);
    isLoggedIn ? dispatch(updateLogin()) : dispatch(updateLogout());
  }, [isLoggedIn, dispatch]);

  const navigate = useNavigate();

  const [extendSesionAPI, { data: extendSessionResposne, isSuccess: isSessionExtended, isError: isErrorWhileExtendingSession, error: errorWhileExtendingSession }] =
    useExtendSessionMutation();

  // const [getProfileAPI, { data: profileData, isLoading: isProfileDataLoading, isSuccess: isGetProfileSuccess, isError: isProfileDataError, error: errorProfileData }] =
  //   useGetUserProfileMutation();

  const clearAndLogout = () => {
    localStorage.clear();
    clearCookies('accessToken');
    dispatch(updateIsSessionExpired(false));
    dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('sessionExpired.message',PageTranslation) }));
    localStorage.clear();
    navigate('/login');
  }

  // const callProfileAPI = () => {
  //   dispatch(fullPageLoader(true));
  //   getProfileAPI({accountType: 'user'});
  // }

  useEffect(() => {
    // i18n.changeLanguage('fr');
    if (extendSessionResposne || isSessionExtended || isErrorWhileExtendingSession || errorWhileExtendingSession) {
      dispatch(fullPageLoader(false));
    }
    if (isErrorWhileExtendingSession) {
      clearAndLogout();
    }
  }, [extendSessionResposne, isSessionExtended, isErrorWhileExtendingSession]);

  useEffect(() => {
    if (isSessionExpired === true) {
      dispatch(fullPageLoader(true));
      extendSesionAPI({
        //"refreshToken": localStorage.getItem('refreshToken')
        "refreshToken": localStorage.getItem('refreshToken')
      });
    }
  }, [isSessionExpired]);

  useEffect(() => {
    if (isRefreshTokenExpired) {
      clearAndLogout();
    }
  }, [isRefreshTokenExpired])

  useEffect(() => {
    if (isSessionExtended) {
      if (extendSessionResposne?.success === true) {
        dispatch(updateIsSessionExpired(false));
        localStorage.setItem('refreshToken', extendSessionResposne?.refreshToken);
        //dispatch(updateAlertMessage({ status: 'success', message: t('message.session_extended') }));
        //dispatch(updateAlertMessage({ status: 'success', message: "Session Extended....." }));
        
        if (reloadPageAfterSessionExpired) {
          window.location.reload();
        }
          
        // setRefresh({});
        // forceUpdate();
      } else {
        dispatch(updateAlertMessage({ status: 'success', message: "Session could not be extended" }));
        clearAndLogout();
      }
    }
  }, [isSessionExtended]);

  // useEffect(() => {
  //   if (isGetProfileSuccess) {
  //     if (profileData.success == true && profileData.statusCode == 200) {
  //       localStorage.setItem('user', JSON.stringify(profileData?.user));
  //       dispatch(updateUser(profileData?.user));
  //       dispatch(fullPageLoader(false));
  //     } else {
  //       clearAndLogout();
  //       dispatch(updateAlertMessage({ status: 'success', message: profileData?.message }));
  //     }
  //   }
  // }, [isGetProfileSuccess]);

  // useEffect(() => {
    
  //   if (isLoggedIn) {
  //     const cachedData = localStorage.getItem('user');
  //     if (cachedData == null) {
  //       console.log('calling profile api-cachedData');
  //       callProfileAPI();
  //     }
  //   }
  // }, [isLoggedIn])


  useEffect(() => {
    const user = localStorage.getItem('user');
    const referrlKey = localStorage.getItem('refKey');
    if (user) {
      dispatch(updateUser(JSON.parse(user)));
    } 
    else if(!referrlKey) {
      localStorage.clear();
    }
  }, []);

  const location = useLocation();
  const imageUrl = `${window.location.protocol}//${window.location.host}/bluePrompt.svg`;
  const fullUrl = `${window.location.protocol}//${window.location.host}${location.pathname}`;

  return (
    <>
        <meta property="og:url" content={fullUrl} />
        <meta property="og:title" content="bluecallom.ai" />
        <meta property="og:description" content="A text that reflects the function of that page.\n Where Generative-AI is at the forefront of the industry." />
        <meta property="og:image" content={imageUrl} />
        
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content={fullUrl} />
        <meta property="twitter:title" content="bluecallom.ai" />
        <meta property="twitter:description" content="A text that reflects the function of that page.\n Where Generative-AI is at the forefront of the industry." />
        <meta property="twitter:image" content={imageUrl} />
      
      <Header />
      <Suspense fallback={<div className='text-center'>{getTranslatedText('loading.message', PageTranslation)}</div>}>
        <Routes>
          <Route path="/login" element={<NonAuthGuard><Login /></NonAuthGuard>} ></Route>
          <Route path="/forgotpwd" element={<NonAuthGuard><Forgotpwd /></NonAuthGuard>} ></Route>
          <Route path="/external/:id" element={<NonAuthGuard><ExternalGateway /></NonAuthGuard>} ></Route>
          <Route path="/home" element={<AppHome />} ></Route>
          <Route path="/app/assetIo" element={<AssetIO />} ></Route>
          <Route path="/app/assetIo/:id" element={<AssetIO />} ></Route>
          <Route path="/app/io/:id" element={<AskGPT />}></Route>
          <Route path="/app/io" element={<AskGPT />}></Route>
          <Route path="/app/askgpt/:id" element={<EmbedPrompt />}></Route>
          {/* <Route path="/app/embed/prompt/:id" element={<EmbedPrompt />} ></Route> */}
          <Route path="/app/exchangeHome" element={<ExchangeHome />} ></Route>
          <Route path="/app/exchangeList" element={<ExchangeList />} ></Route>
          <Route path="/app/embed/library/:id" element={<EmbedLibrary />} ></Route>
          <Route path="/app/*" element={<AuthGuard><Secure /></AuthGuard>} ></Route>
          <Route path="/referral/:id" element={<NonAuthGuard><Referral /></NonAuthGuard>} />
          <Route path="/company/:id" element={<AppHome />}></Route>
          <Route path="/unsubscribe/:id" element={<UnsubscribeUser />}></Route>
          <Route path="*" element={<Navigate to="/home" replace />} ></Route>
        </Routes>
      </Suspense>
      <Footer />
      {fullPageLoaderSelector && 
        <div className='fullpage-loader' >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">
              Loading…
            </span>
          </div>
        </div>
      }
      <Alert />
    </>
  );
}

export default App;
