import React, { useEffect, useState } from 'react';
import Card from './card/card';
import { useLocation, useNavigate } from 'react-router-dom';
import TooltipComponent from './bootstrap-component/tooltip-component';
import { getTranslatedText } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';
import EditTranslationIcon from './edit-translation-icon/EditTranslationIcon';
import { useSelector } from 'react-redux';
import DraggableAgent from '../secure/solution-studio/draggable-agent';

interface AgentListProps {
    cardId: string;
    cardTitle: string;
    cardHelpTitle: string;
    cardHelpContent: string;
    agentsList?: any;
    passedSolutionId?: string;
    isAgentsLoading: boolean;
    isAgentsSuccess: boolean;
    setChildAgentCompletionMsg?: (msg: string) => void;
    activeId?: string | null;
    setLocalAgentInstanceId?: (instanceId: number) => void;
}

type SortCriteria = "newest_date" | "updated_date" | "alphabetical";

const AgentList: React.FC<AgentListProps> = (props) => {
    const navigate = useNavigate();
    const PageTranslation = usePageTranslation();
    const {  TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
    const location = useLocation();

    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [sortingData, setSortingData] = useState<any[]>([]);
    const [sortCriteria, setSortCriteria] = useState<SortCriteria>("alphabetical");
    const [isToggled, setIsToggled] = useState<boolean>(true);    
    const [searchQuery, setSearchQuery] = useState<string>('');


    useEffect(() => {
        setFilteredData(props?.agentsList);
    },[props?.agentsList]);

    useEffect(() => {
        let filteredAgentData = props?.agentsList || [];
        if (searchQuery !== '') {
            filteredAgentData = filteredAgentData.filter(
                (item: any) => item.AGENTNAME && item.AGENTNAME.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }        
        setFilteredData(filteredAgentData);
    }, [searchQuery, props?.agentsList]);

    useEffect(() => {
        if (props?.agentsList) {
            if(filteredData?.length === 0) {
                setSortingData([]);
                return;
            }
            const sortedData = [...filteredData].sort((a, b) => {
                switch (sortCriteria) {
                case "newest_date":
                    if (isToggled) {
                    return (
                        new Date(a?.CREATEDTIMESTAMP).getTime() - new Date(b?.CREATEDTIMESTAMP).getTime()
                        );
                    } else {
                    return (
                        new Date(b?.CREATEDTIMESTAMP).getTime() - new Date(a?.CREATEDTIMESTAMP).getTime()
                    );
                    }
                case "updated_date":
                    if (isToggled) {
                    return (
                        new Date(a?.UPDATEDTIMESTAMP).getTime() - new Date(b?.UPDATEDTIMESTAMP).getTime()
                    );
                    } else {
                    return (
                        new Date(b?.UPDATEDTIMESTAMP).getTime() - new Date(a?.UPDATEDTIMESTAMP).getTime()
                    );
                    }
                case "alphabetical":
                    const nameA = a?.AGENTNAME || '';
                    const nameB = b?.AGENTNAME || '';
                    return isToggled ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                default:
                    return 0;
                }
            });
            setSortingData(sortedData);
        }
    }, [sortCriteria, isToggled, filteredData, props?.agentsList]);

    const handleToggle = (param: SortCriteria) => {
        setSortCriteria(param);
        setIsToggled(!isToggled);
    };

    return (
        <Card id={props?.cardId}
            titleType={1} title={props?.cardTitle}
            help={true} Feedback={true} logo={true}
            helpTitle={props?.cardHelpTitle}
            helpContent={props?.cardHelpContent}
        >
            {props.isAgentsLoading && (
                <div className="text-center">
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {props.isAgentsSuccess && sortingData && sortingData.length > 0 &&
                <h6 className="mb-3 d-flex justify-content-evenly">
                    <TooltipComponent title={getTranslatedText('sortByAlphabetical.tooltip', PageTranslation)}>
                    <span className="cursor-pointer" onClick={() => handleToggle("alphabetical")}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByAlphabetical.text', PageTranslation)}} />
                    </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                    <>
                        <EditTranslationIcon translationKey="sortByAlphabetical.text" translationType='text'/>
                        <EditTranslationIcon translationKey="sortByAlphabetical.tooltip" translationType='tooltip' />
                    </>
                    )}
                    &nbsp; | &nbsp;
                    <TooltipComponent title={getTranslatedText('sortByNewest.tooltip', PageTranslation)}>
                    <span className="cursor-pointer" onClick={() => handleToggle("newest_date")}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByNewest.text', PageTranslation)}} />
                    </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                    <>
                        <EditTranslationIcon translationKey="sortByNewest.text" translationType='text'/>
                        <EditTranslationIcon translationKey="sortByNewest.tooltip" translationType='tooltip' />
                    </>
                    )}
                    &nbsp; | &nbsp;
                    <TooltipComponent title={getTranslatedText('sortByUpdatedOn.tooltip', PageTranslation)}>
                    <span className="cursor-pointer" onClick={() => handleToggle("updated_date")}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByUpdatedOn.text', PageTranslation)}} />
                    </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                    <>
                        <EditTranslationIcon translationKey="sortByUpdatedOn.text" translationType='text'/>
                        <EditTranslationIcon translationKey="sortByUpdatedOn.tooltip" translationType='tooltip' />
                    </>
                    )}
                </h6>
            }
            {props.isAgentsSuccess && props?.agentsList?.length > 0 && (
                <div className="mb-3">
                    <input
                    className='form-control'
                    type="text"
                    placeholder={getTranslatedText('agentSearch.placheloder', PageTranslation)}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {TranslationEditFlag && <EditTranslationIcon translationKey='agentSearch.placheloder' translationType='placeholder'/>}
                </div>
            )}
            {props.isAgentsSuccess &&!props.passedSolutionId && sortingData && sortingData?.length === 0 && (
                <>
                <span dangerouslySetInnerHTML={{__html: getTranslatedText('noAgents.message',PageTranslation)}}></span>
                {TranslationEditFlag && <EditTranslationIcon translationKey="noAgents.message" translationType='text'/>}
                </>
            )}
            {props.isAgentsSuccess && !props.passedSolutionId && sortingData?.length > 0 && 
            <div className='overflow-auto scrollbar h-35vh'>
                {sortingData?.map((agent: any, index:number) => (
                    <h6 key={index} className='mb-3'>
                        <span 
                        className='cursor-pointer' 
                        onClick={() => {
                            props.setChildAgentCompletionMsg && props.setChildAgentCompletionMsg('');
                            if(location.pathname === '/app/agentStudio') {
                                navigate('/app/agentStudio', { state: { passedAgentId: agent?.AGENTID } });
                            }
                            else {
                                navigate(`/app/assetIo/${agent?.AGENTURLCODE}&assetType=agent`);
                                props.setLocalAgentInstanceId && props.setLocalAgentInstanceId(0);
                            }
                        }}
                        >
                            {agent?.AGENTNAME}
                        </span>
                    </h6>
                ))}
            </div>
            }
            {props.isAgentsSuccess && props.passedSolutionId && (
                <>
                    <div className='mb-2'>
                        <span className='fw-bold cursor-pointer' onClick={() => {navigate('/app/solutionStudio');}}>
                        <TooltipComponent title={getTranslatedText('backToSolutions.tooltip',PageTranslation)}>
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('backToSolutions.text',PageTranslation)}}/>
                        </TooltipComponent>
                        </span>
                        {
                            TranslationEditFlag && (
                                <>
                                    <EditTranslationIcon translationKey='backToSolutions.text' translationType='text'/>
                                    <EditTranslationIcon translationKey='backToSolutions.tooltip' translationType='tooltip'/>
                                </>
                            )
                        }  
                    </div>
                    <div className='overflow-auto scrollbar h-35vh'>
                        {sortingData?.map((agent: any, index:number) => (
                            <DraggableAgent key={index} 
                            agent={agent} 
                            containerId="left" 
                            activeId={props.activeId}
                            />
                        ))}
                    </div>
                </>
            )}
        </Card>
    );
};

export default AgentList;
