import React, { useState, useEffect } from 'react';
import Card from './card/card';
import { IAgentData, IExtendedPromptData, IPromptData } from '../../interfaces/common/assets.interface';
import Flag from '../../assets/icons/bluePrompt.svg';
import Delete from '../../assets/icons/delete.svg';
import TooltipComponent from './bootstrap-component/tooltip-component';
import { convertToThousands, extractDnaValue, getRemainingCharacters, getTranslatedText } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';
import EditTranslationIcon from './edit-translation-icon/EditTranslationIcon';
import { useDispatch, useSelector } from 'react-redux';
import {  useForm } from 'react-hook-form';
import { fullPageLoader, updateAlertMessage } from '../../api-integration/commonSlice';
import { useNavigate } from 'react-router-dom';
import WelcomeNonLoginCard from "../common/modal/welcome-non-login-user";
import ConfirmationPopup from './modal/confirmation-popup';
import { useRemoveAssetFromLibraryMutation } from '../../api-integration/secure/secure';
import PromptExecutionStepper from './prompt-execution-stepper';
import PromptAdditionalInfoModal from './modal/prompt-additional-info-modal';


interface AssetExecutionProps {
    cardId: string;
    cardTitle: string;
    cardHelpTitle: string;
    cardHelpContent: string;
    paramAssetType: string;
    //selectedAgentDetails: IAgentData | undefined;
    //selectedPromptDetails: IPromptData | undefined;
    //isLibraryTypeChanged: boolean,
    aiResponseloading: boolean;
    // responseAverageCCUsed: number;
    agentApiErrorMsg?: string; //optional, only for agent api error message
    agentCompletionMsg?: string; //optional, only when agent is completed
    agentAllDetails: any;
    //setWiderLayout: (data: boolean) => void;
    //setReloadPromptList: (reloadPromptList: boolean) => void;
    setExecutionFormData: (data: any) => void;
    setReloadAgentToExecute: (reload: boolean) => void;
    setLocalAgentInstanceId: (instanceId: number) => void;
}

const AssetExecution: React.FC<AssetExecutionProps> = (props) => {
    const PageTranslation = usePageTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const DUALPROMPTREFERENCEDATA_MAXLENGTH = 2500, 
    DEFAULTACTION_MAXLENGTH = 2500;
    const { user, TranslationEditFlag, LibraryType} = useSelector((state: any) => state.commonSlice);
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
    const [isPromptDetailsAvailable, setIsPromptDetailsAvailable] = useState(false);
    const { register, handleSubmit, trigger, formState: { errors }, setValue, getValues, watch } = useForm<IPromptData>();
    const userPromptHasValue = watch('DEFAULTACTION');
    const referenceDataHasValue = watch('DUALPROMPTREFERENCEDATA');
    const [referenceDataCCount, setReferenceDataCCount] = useState(DUALPROMPTREFERENCEDATA_MAXLENGTH);  
    const [defaultActionCCount, setDefaultActionCCount] = useState(DEFAULTACTION_MAXLENGTH);
    const [localDefaultProductivity, setLocalDefaultProductivity] = useState(2);
    const [selectedAgentDetails, setSelectedAgentDetails] = useState<IAgentData | undefined>(undefined);
    const [selectedPromptDetails, setSelectedPromptDetails] = useState<IPromptData | undefined>(undefined);
    const [generateResponseBtntooltipKey, setGenerateResponseBtntooltipKey] = useState('');
    const [dontShowExeAlert, setDontShowExeAlert] = useState<boolean>(true);
    const [averageCCUsed, setAverageCCUsed] = useState(0);
    const [cardBottomAppenderText, setCardBottomAppenderText] = useState('');
    const [localAgentWorkflowArrLength, setLocalAgentWorkflowArrLength] = useState(0);
    const [localMessageToDisplay, setLocalMessageToDisplay] = useState('');
    const [promptSteps, setPromptSteps] = useState<any>([]);    
    const [activeStep, setActiveStep] = useState(0);
    const [editAccess, setEditAccess] = useState(false);
    const [automaticPrompt, setAutomaticPrompt] = useState<boolean>(false);
    const [automaticPromptMsg, setAutomaticPromptMsg] = useState<string>('');
    const [agentTotalCCUsed, setAgentTotalCCUsed] = useState(0);
    const [isAutomaticPromptLoading, setIsAutomaticPromptLoading] = useState<boolean>(false);
    
    //API Calls
    const [removeAssetFromLibAPI, {data: removeAssetFromLibData, isSuccess: isRemoveAssetFromLibSuccess,  isError: isRemoveAssetFromLibError, error: removeAssetFromLibError}] 
    = useRemoveAssetFromLibraryMutation();

    useEffect(() => {
        if(user?.promptExeAlert === 1){
            setDontShowExeAlert(false);
        }else{
            setDontShowExeAlert(true);
        }
    }, [user?.promptExeAlert]);

    // useEffect(() => {
    //     if(props?.isLibraryTypeChanged){
    //         setIsPromptDetailsAvailable(false);
    //     }
    // }, [props?.isLibraryTypeChanged]);
    
    useEffect(() => {
        setIsAutomaticPromptLoading(false);
        if (props.agentAllDetails !== undefined) {
            setSelectedAgentDetails(props.agentAllDetails?.agentDetails);
            setSelectedPromptDetails(props.agentAllDetails?.promptToExecuteData?.promptDetail?.[0]);
            setAgentTotalCCUsed(props.agentAllDetails?.agentInstance?.TotalCCUsed || 0);
            props.setLocalAgentInstanceId(props.agentAllDetails?.agentInstance?.INSTANCEID || 0);
            let dnaCharacter;
            if (props.agentAllDetails?.promptToExecuteData) {
                const currentPromptDna = props.agentAllDetails?.promptToExecuteData?.promptDetail?.[0].PROMPTDNA;
                dnaCharacter = extractDnaValue(currentPromptDna, 3, 1);
            }
    
            let timer: NodeJS.Timeout | null = null;
            let automaticPromptMsg: string = "";
            if(dnaCharacter !== 'H') {   
                setAutomaticPrompt(true);             
                automaticPromptMsg = "automaticPrompt.message";
                if (props.agentAllDetails?.agentInstance?.INSTANCESTATUS === 1) {
                    setIsAutomaticPromptLoading(true);
                    automaticPromptMsg = "automaticAgentExecute.message";
                    timer = setTimeout(() => {
                        props.setReloadAgentToExecute(true);
                    }, 10000); // 10 seconds
                } 
            }
            else {
                automaticPromptMsg = "";
                setAutomaticPrompt(false);
                setIsAutomaticPromptLoading(false);
            }
            setAutomaticPromptMsg(automaticPromptMsg);
            const allPromptsAvailable = props.agentAllDetails?.agentDetails?.agentWorkflow?.map((prompt: any) => ({
                PROMPTID: prompt.PROMPTWORKFLOWPROMPTID,
                PROMPTNAME: prompt.PROMPTNAME,
                PROMPTDESCRIPTION: prompt.PROMPTDESCRIPTION,
                PROMPTIMAGE: prompt.promptImage
            })) || [];
            setAverageCCUsed(props?.agentAllDetails?.agentDetails?.averageCCUsed || 0);
            let executedPrompts: any[] = [];
            let nextPrompt: any = {};
            let nextPromptID: number | null = null;
            let remainingPrompts: any[] = [];
    
            if (props?.agentCompletionMsg === '') {
                const executedPromptIds = props.agentAllDetails?.agentInstance?.executedPromptData
                    ?.filter((prompt: any) => prompt.PROMPTEXECUTIONSTATUS === 2)
                    .map((prompt: any) => prompt.PROMPTID) || [];
                    
                executedPrompts = allPromptsAvailable.filter((prompt: any) =>
                    executedPromptIds.includes(prompt.PROMPTID)
                );
                
                nextPrompt = props.agentAllDetails?.promptToExecuteData?.promptDetail?.[0] || {};
                nextPromptID = nextPrompt.GPTBLUEPROMPTID || null;
    
                remainingPrompts = allPromptsAvailable.filter((prompt: any) =>
                    !executedPromptIds.includes(prompt.PROMPTID) && prompt.PROMPTID !== nextPromptID
                );
            } else {
                executedPrompts = allPromptsAvailable;
            }
    
            const steps = [
                ...executedPrompts.map(({ PROMPTNAME, PROMPTIMAGE }: any) => ({
                    label: "",
                    tooltip: PROMPTNAME,
                    image: PROMPTIMAGE
                })),
                ...(nextPromptID ? [{
                    label: "",
                    tooltip: nextPrompt?.PROMPTNAME || "",
                    image: nextPrompt?.promptImage || ""
                }] : []),
                ...remainingPrompts.map(({ PROMPTNAME, PROMPTIMAGE }: any) => ({
                    label: "",
                    tooltip: PROMPTNAME,
                    image: PROMPTIMAGE
                }))
            ];
    
            setPromptSteps(steps);
            setActiveStep(executedPrompts.length);
    
            // Cleanup function
            return () => {
                if (timer) clearTimeout(timer);
            };
        }
    }, [props.agentAllDetails, props?.agentCompletionMsg]);

    useEffect(() => {
        if(selectedPromptDetails){
            setValue('DUALPROMPTREFERENCEDATA', selectedPromptDetails?.DUALPROMPTREFERENCEDATA);
            setReferenceDataCCount(getRemainingCharacters(DUALPROMPTREFERENCEDATA_MAXLENGTH, selectedPromptDetails?.DUALPROMPTREFERENCEDATA.length));
            setValue('DEFAULTACTION', selectedPromptDetails?.DEFAULTACTION);
            setDefaultActionCCount(getRemainingCharacters(DEFAULTACTION_MAXLENGTH, selectedPromptDetails?.DEFAULTACTION.length));
            // Bottom Text
            if(selectedPromptDetails?.OWNERFIRSTNAME && selectedPromptDetails?.OWNERLASTNAME){
                setCardBottomAppenderText(`${selectedPromptDetails?.OWNERFIRSTNAME} ${selectedPromptDetails?.OWNERLASTNAME}`);
            }
        }
    }, [selectedPromptDetails, PageTranslation]);

    // This useEffect is created because when the userPromptHasValue or  referenceDataHasValue
    // changes, we need to set the response tooltip.
    // This logic cannot be written in the above useEffect because there we are setting the value of 
    // DUALPROMPTREFERENCEDATA and DEFAULTACTION from the selectedPromptDetails.
    // So if we use it in the above useEffect, we will always set the default value coming from the API
    useEffect(() => {
        //START: Set generateResponseBtntooltipKey Section
        //Dual Prompt
        if(selectedPromptDetails?.DUALPROMPT){
            if (userPromptHasValue && referenceDataHasValue) {
                //value in both fields
                setGenerateResponseBtntooltipKey('askButton.tooltip');
            }
            else if (!userPromptHasValue && !referenceDataHasValue) {
                //no value in both fields
                setGenerateResponseBtntooltipKey('referenceAndActionDataRequired.tooltip');
            }
            else if (!userPromptHasValue) {
                //no value in user prompt (input data [label show on screen] in case of dual prompt)
                setGenerateResponseBtntooltipKey('actionDataRequired.tooltip');
            } 
            else{
                //no value in reference data
                setGenerateResponseBtntooltipKey('referenceDataRequired.tooltip');
            }                                            
        }
        //Survey Prompt
        else if (selectedPromptDetails?.SURVEYPROMPT){
            if (userPromptHasValue) {
                //value in user prompt
                setGenerateResponseBtntooltipKey('reviewResponse.tooltip');
            }
            else {
                //no value in user prompt
                setGenerateResponseBtntooltipKey('promptRequiredForResponse.tooltip');
            }
        }
        //Normal Prompt
        else if (userPromptHasValue) {
            //value in user prompt
            setGenerateResponseBtntooltipKey('askButton.tooltip');
        }
        //Normal Prompt
        else {
            //no value in user prompt
            setGenerateResponseBtntooltipKey('promptRequiredForResponse.tooltip');
        }
        //END: Set generateResponseBtntooltipKey Section
    }, [selectedPromptDetails, userPromptHasValue, referenceDataHasValue]);

    useEffect(() => {
        if(!selectedPromptDetails || (props?.agentCompletionMsg && props?.agentCompletionMsg !== '')){
            setIsPromptDetailsAvailable(false);
        }
        else{
            setIsPromptDetailsAvailable(true);
        }
    }, [selectedPromptDetails, props?.agentCompletionMsg]);

    useEffect(() => {
        if(selectedAgentDetails){
            setLocalAgentWorkflowArrLength(selectedAgentDetails?.agentWorkflow?.length || 0);
            // Bottom Text
            if(selectedAgentDetails?.CREATEDBYFIRSTNAME && selectedAgentDetails?.CREATEDBYLASTNAME){
                setCardBottomAppenderText(`${selectedAgentDetails?.CREATEDBYFIRSTNAME} ${selectedAgentDetails?.CREATEDBYLASTNAME}`);
            }
        }        
        if(user?.userId === selectedAgentDetails?.CREATEDBYUSERID) {
            setEditAccess(true);
        }
        else {
            setEditAccess(false);
        }
    }, [selectedAgentDetails]);

    useEffect(() => {
        if (isRemoveAssetFromLibSuccess) {
            dispatch(fullPageLoader(false));
            if(removeAssetFromLibData.success === true){
                dispatch(updateAlertMessage({ status: 'success', message: removeAssetFromLibData?.message }));
                navigate('/app/assetIo');
                props.setReloadAgentToExecute(true);
            }
            else {
                dispatch(updateAlertMessage({ status: 'error', message: removeAssetFromLibData?.message }));
            }
        }
        if (isRemoveAssetFromLibError || removeAssetFromLibError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: removeAssetFromLibData?.message }));
        }
    },[isRemoveAssetFromLibSuccess, isRemoveAssetFromLibError, removeAssetFromLibError]);

    const onSubmit = async (data: IPromptData) => {
        let dataToExecute = {...data};
        if(automaticPrompt === true) {
            dataToExecute.DEFAULTACTION = props.agentAllDetails?.promptToExecuteData?.promptDetail?.[0]?.DEFAULTACTION || "";
        }
        if(dontShowExeAlert){
            executePrompt(dataToExecute);
        }
    };

    const executePrompt = (data: IPromptData ) => {
        const extendedData: IExtendedPromptData = {
            ...data,
            GPTBluePromptId: selectedPromptDetails?.GPTBLUEPROMPTID || 0,
            accountType: LibraryType === 'org' ? 'corp' : 'user',
            authorView: data?.AUTHORVIEW ? 1 : 0,
            ManualEffort: localDefaultProductivity.toString(),
            userPrompt: data?.DEFAULTACTION,
            promptExeAlert: dontShowExeAlert ? 0 : 1  //if checkbox is checked i.e. dont show execution alert in future so pass 0 else pass 1
        };
        props.setExecutionFormData(extendedData);
    };

    const handleConfirmPromptExecution = () => {
        trigger().then((isValid) => {
            if (isValid) {
                executePrompt(getValues());
            }
        });
    };

    const handleConfirmDeleteDownloadedAgent = () => {
        dispatch(fullPageLoader(true));
        removeAssetFromLibAPI({ 
            assetId: selectedAgentDetails?.AGENTID,
            assetType: "agent"
        });
    };
    
    useEffect(() => {
        if (!selectedAgentDetails && props?.paramAssetType === 'agent') {
            setLocalMessageToDisplay('ioSelectAgentloggedIn.message');
        } 
        else if (localAgentWorkflowArrLength === 0 && selectedAgentDetails && props?.paramAssetType === 'agent') {
            setLocalMessageToDisplay('noPromptExistsInAgent.message');
        } 
        else {
            setLocalMessageToDisplay('');
         }
    }, [selectedAgentDetails, props?.paramAssetType, selectedPromptDetails, localAgentWorkflowArrLength]);

    return (
        <>
        <Card 
            id={props?.cardId} titleType={1} 
            title={props?.cardTitle}
            logo={true} Feedback={true}
            // cardHeightClass='h-100' 
            help={true}
            settings={editAccess ? true : false}
            settingsClicked={() => navigate('/app/agentStudio', { state: { passedAgentId: selectedAgentDetails?.AGENTID } })}
            helpTitle={props?.cardHelpTitle}
            helpContent={props?.cardHelpContent}
            bottomTextFlag={(isPromptDetailsAvailable || selectedAgentDetails?.AGENTID) ? true : false} 
            bottomText="author.text"
            bottomAppenderText={cardBottomAppenderText}
        >
            <div className='d-flex flex-column align-content-between h-100'>
            {/* Agent Details Section */}
            {selectedAgentDetails &&
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row g-2 mb-3">
                            <div className="col-lg-2 text-center">
                                {/* Agent Image */}
                                <div className="">
                                    <img src={selectedAgentDetails?.agentImage} className="img-fluid h-7 border rounded" alt= {getTranslatedText('imageAlt.text', PageTranslation)} />
                                </div>
                            </div>
                            <div className="col-lg-10">
                                {/* Agent Name */}
                                <div>
                                    {selectedAgentDetails?.AGENTNAME}
                                </div>
                                {/* Agent Instructions */}
                                {selectedAgentDetails?.AGENTINSTRUCTIONTOUSER &&
                                    <div className="my-2 overflow-auto maxH-5 text-break scrollbar">
                                        {selectedAgentDetails?.AGENTINSTRUCTIONTOUSER}
                                    </div>
                                }
                                {/* Productivity */}
                                <div>
                                    {TranslationEditFlag && <EditTranslationIcon translationKey='agentProductivity.text' translationType='text'/>}
                                    {getTranslatedText('agentProductivity.text',PageTranslation)}: {convertToThousands(props.agentAllDetails?.agentInstance?.Productivity || 0)}
                                </div>
                                {/* Total CC Used */}
                                <div>
                                    {TranslationEditFlag && <EditTranslationIcon translationKey='totalCCUsed.text' translationType='text'/>}
                                    {getTranslatedText('totalCCUsed.text',PageTranslation)}: {convertToThousands(agentTotalCCUsed.toString())}
                                </div>
                            </div>
                        </div>
                        
                        <PromptExecutionStepper 
                            activeStep={activeStep}
                            steps={promptSteps}
                            progressBarData={props.agentAllDetails}
                        />
                        <hr className="my-3 border border-primary border-1 opacity-75" />
                    </div>
                </div>
            }
            {/* Msg Section */}
            {props?.agentCompletionMsg &&
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h3 dangerouslySetInnerHTML={{__html: props?.agentCompletionMsg}} />
                    </div>
                </div>
            }
            {/* START: this is temporary fix for the issue where the agent execution is complete, it should be handled from API side */}
            {!props?.agentCompletionMsg &&  props.agentAllDetails?.agentInstance?.INSTANCESTATUS === 2 &&
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h3 dangerouslySetInnerHTML={{__html: "Agent execution is complete. All prompts have been executed. To execute another agent, please select an agent from the agent list."}} />
                    </div>
                </div>
            }
            {/* END: this is temporary fix for the issue where the agent execution is complete, it should be handled from API side */}

            {props?.agentApiErrorMsg &&
                <div className="row">
                    <div className="col-lg-12 text-center text-danger">
                        <h4 dangerouslySetInnerHTML={{__html: props?.agentApiErrorMsg}} />
                    </div>
                </div>
            }
            {((!isPromptDetailsAvailable) && !props?.agentCompletionMsg && !props?.agentApiErrorMsg) &&
                <div className="row">
                    <div className="col-lg-12 text-center text-danger mb-3">
                        <span dangerouslySetInnerHTML={{__html:getTranslatedText(localMessageToDisplay, PageTranslation)}} />
                        {TranslationEditFlag && <EditTranslationIcon translationKey={localMessageToDisplay} translationType="text" />}
                    </div>
                </div>
            }
            {isPromptDetailsAvailable && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {automaticPrompt === false &&
                            <>
                                <div className="row">
                                    <div className="col-lg-7">
                                            {/* Prompt Name */}
                                            <div className="mb-3 cursor-pointer"
                                                data-bs-toggle="modal" 
                                                data-bs-target='#prompt_name_help'
                                            >
                                                {selectedPromptDetails?.BLUEPROMPT === 1 && 
                                                    <img src={Flag} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='h-1-5'/>
                                                }
                                                <TooltipComponent title={getTranslatedText("promptDetails.tooltip", PageTranslation)}>
                                                    {selectedPromptDetails?.PROMPTNAME}
                                                </TooltipComponent>
                                                {TranslationEditFlag && <EditTranslationIcon translationKey="promptDetails.tooltip" translationType="tooltip"/>}
                                            </div>
                                            {/* Prompt Instructios */}
                                            {selectedPromptDetails?.INSTRUCTIONTOUSER &&
                                                <div className="my-2 p-1 overflow-auto maxH-5 text-break scrollbar">
                                                    {selectedPromptDetails?.INSTRUCTIONTOUSER}
                                                </div>
                                            }
                                            {/* Prompt Expiry */}
                                            {selectedPromptDetails?.EXPIREON &&
                                                <div className="my-2">
                                                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('expiryPrompt.text', PageTranslation)}} className="fw-bold" />
                                                    {TranslationEditFlag && (
                                                        <EditTranslationIcon translationKey="expiryPrompt.text" translationType='text'/>
                                                    )}:
                                                    &nbsp;
                                                    {selectedPromptDetails?.EXPIREON ? new Date(selectedPromptDetails?.EXPIREON).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }) + ' | ' + new Date(selectedPromptDetails?.EXPIREON).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : '---'}
                                                    &nbsp;CET
                                                </div>
                                            }
                                            {/* Prompt Reference Data */}
                                            {selectedPromptDetails?.DUALPROMPT === 1 && 
                                            <div className="mb-3">
                                                <label htmlFor="referenceData" className="fw-bold">
                                                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('referenceData.text', PageTranslation)}} />
                                                    {TranslationEditFlag && (
                                                        <EditTranslationIcon translationKey="referenceData.text" translationType='text'/>
                                                    )}
                                                </label>
                                                <textarea className="form-control" 
                                                    id="referenceData" 
                                                    {...register('DUALPROMPTREFERENCEDATA', { required: true })}
                                                    rows={8} maxLength={DUALPROMPTREFERENCEDATA_MAXLENGTH}
                                                    onChange={e => {
                                                        setReferenceDataCCount(getRemainingCharacters(DUALPROMPTREFERENCEDATA_MAXLENGTH, e.target.value.length));
                                                        setValue('DUALPROMPTREFERENCEDATA', e.target.value);
                                                    }}
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.DUALPROMPTREFERENCEDATA && errors.DUALPROMPTREFERENCEDATA.type === 'required' && 
                                                        <>
                                                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('referenceData.required.message', PageTranslation)}} />
                                                        {TranslationEditFlag && <EditTranslationIcon translationKey="referenceData.required.message" translationType="text" />}
                                                        </>
                                                    }
                                                </div>
                                                <div>
                                                    <small className="text-muted">
                                                        {referenceDataCCount}
                                                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('charsRemaining.text', PageTranslation)}} />
                                                        {TranslationEditFlag && <EditTranslationIcon translationKey="charsRemaining.text" translationType="text" />}
                                                    </small>
                                                </div>
                                            </div>
                                            }
                                            {/* Default Action */}
                                            <label htmlFor="default_action" className="fw-bold">
                                                <span dangerouslySetInnerHTML={{__html: selectedPromptDetails?.DUALPROMPT === 1 ? getTranslatedText('actionData.text', PageTranslation) : getTranslatedText('userPrompt.text', PageTranslation)}} />
                                                {TranslationEditFlag && (
                                                    <EditTranslationIcon translationKey={selectedPromptDetails?.DUALPROMPT === 1 ? "actionData.text" : "userPrompt.text"} translationType='text'/>
                                                )}
                                            </label>
                                            <div className="mb-3">
                                                <textarea 
                                                    className={`form-control scrollbar ${errors?.DEFAULTACTION ? 'is-invalid' : ''}`} 
                                                    id="default_action" 
                                                    {...register('DEFAULTACTION', { required: true })} 
                                                    rows={3} 
                                                    maxLength={DEFAULTACTION_MAXLENGTH}
                                                    onChange={e => {
                                                    setDefaultActionCCount(getRemainingCharacters(DEFAULTACTION_MAXLENGTH, e.target.value.length));
                                                    setValue('DEFAULTACTION', e.target.value);
                                                }}
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.DEFAULTACTION && errors.DEFAULTACTION.type === 'required' &&
                                                        <>
                                                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('defaultAction.required.message', PageTranslation)}} />
                                                        {TranslationEditFlag && <EditTranslationIcon translationKey="defaultAction.required.message" translationType="text" />}
                                                        </>
                                                    }
                                                </div>
                                                <small className="form-text text-muted">
                                                    {defaultActionCCount} 
                                                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('charsRemaining.text', PageTranslation)}} />
                                                    {TranslationEditFlag && <EditTranslationIcon translationKey="charsRemaining.text" translationType="text" />}
                                                </small>
                                            </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>
                                                    <TooltipComponent title={getTranslatedText('promptProductivity.tooltip', PageTranslation)}>
                                                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('promptProductivity.text', PageTranslation)}} />
                                                    </TooltipComponent>
                                                </span>
                                                {TranslationEditFlag && 
                                                    <>
                                                    <EditTranslationIcon translationKey="promptProductivity.text" translationType="text"/>
                                                    <EditTranslationIcon translationKey="promptProductivity.tooltip" translationType="tooltip"/>
                                                    </>
                                                }
                                                <div className="input-group input-group-sm">
                                                    <input type="number" 
                                                        value={localDefaultProductivity}
                                                        step="0.25" id="ManualEffort"
                                                        className='form-control form-control-sm text-end'
                                                        aria-label="gainValue"
                                                        aria-describedby="gainValue"
                                                        max = {3000}
                                                        maxLength={4}
                                                        onKeyDown={(e) => {
                                                            if (['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                                                                return;
                                                            }
                                                            let inputValue = Number((e.target as HTMLInputElement).value + e.key);
                                                            if (inputValue > 3000) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setLocalDefaultProductivity(Number(e.target.value))
                                                        }}
                                                    />
                                                    <span className="input-group-text" id="gainValue" dangerouslySetInnerHTML={{ __html: getTranslatedText('manualHours.text', PageTranslation)}}></span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="pe-1">A</div>
                                                        <strong>{new Intl.NumberFormat('en-US').format(Math.round(((localDefaultProductivity * 60) / 5) * 100) / 100)} x</strong>
                                                    <div className="ps-1">
                                                        {getTranslatedText('gain.text', PageTranslation)}
                                                        {TranslationEditFlag && <EditTranslationIcon translationKey="gain.text" translationType="text"/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className='mt-2 text-end'>
                                            <img 
                                                src={selectedPromptDetails?.promptImage} 
                                                className={"img-fluid h-7 " + (selectedPromptDetails?.BLUEPROMPT === 1 ? "border border-primary rounded" : (selectedPromptDetails?.OPENSOURCE === 1 ? "border bl_border-orange rounded" : "border border-dark-subtle rounded"))} 
                                                alt={getTranslatedText('imageAlt.text', PageTranslation)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {automaticPrompt === true && (
                            <>
                                <div className={`text-start ${isAutomaticPromptLoading ? 'bc-blinking-text' : ''}`}>
                                    {getTranslatedText(automaticPromptMsg, PageTranslation)}
                                    {isAutomaticPromptLoading && (
                                        <div className="ms-2 spinner-border spinner-border-sm text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                    {TranslationEditFlag && <EditTranslationIcon translationKey={automaticPromptMsg} translationType="text"/>}
                                </div>
                            </>
                        )}
                        {/* For human as well as automatic prompt, isAutomaticPromptLoading will be false. 
                            We need to pass  isAutomaticPromptLoading as false so that the submit button does not appear when the agent is being executed automatically.
                        */}
                        {(isAutomaticPromptLoading  === false && automaticPrompt === false) && 
                            <div className='text-center mt-3'>
                                {/* <TooltipComponent title={automaticPrompt === true ? getTranslatedText('submit.tooltip',PageTranslation) : getTranslatedText(generateResponseBtntooltipKey, PageTranslation)}> */}
                                <TooltipComponent title={getTranslatedText(generateResponseBtntooltipKey, PageTranslation)}>    
                                    <button 
                                        id="savePromptBtn" type="submit" 
                                        className="btn btn-primary btn-md rounded-pill px-4"
                                        disabled={automaticPrompt === false && (props?.aiResponseloading || !userPromptHasValue || (selectedPromptDetails?.DUALPROMPT === 1 && !referenceDataHasValue))}
                                        data-bs-toggle={dontShowExeAlert ? undefined : "modal"}
                                        data-bs-target={dontShowExeAlert ? undefined : "#agentExecutingConfirmModal"}
                                    >
                                        {selectedPromptDetails?.SURVEYPROMPT 
                                        ?
                                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('reviewResponse.text', PageTranslation)}} />
                                        : 
                                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('submit.text', PageTranslation)}} />
                                        }
                                    </button>
                                </TooltipComponent>
                                {TranslationEditFlag && 
                                    <>
                                    <EditTranslationIcon translationKey={selectedPromptDetails?.SURVEYPROMPT ? "reviewResponse.text" : "submit.text"} translationType="text"/>
                                    <EditTranslationIcon translationKey={generateResponseBtntooltipKey} translationType="tooltip"/>
                                    {/* <EditTranslationIcon translationKey={automaticPrompt === true ? "submit.tooltip" : generateResponseBtntooltipKey} translationType="tooltip"/> */}
                                    </>
                                }
                            </div>
                        }            
                    </form>
                </>
            )}
            {props?.agentAllDetails && props.paramAssetType === 'agent' && (
                <>
                    <div className="row mt-3 mt-auto">
                        <div className="col-lg-12 text-end">
                            {props?.agentAllDetails?.agentDetails?.AGENTMYPERSONALLIB === 1 &&
                                <div className="d-inline-block text-center px-2">
                                    <TooltipComponent title={props?.agentAllDetails?.agentDetails?.MYROLEID === 1 ? getTranslatedText('authorRemoveAgentFromLibrary.tooltip', PageTranslation) : getTranslatedText('userRemoveAgentFromLibrary.tooltip', PageTranslation)} >
                                        <div className="mb-1">
                                            <small>{getTranslatedText('remove.text', PageTranslation)}</small>
                                        </div>
                                        <img src={Delete} 
                                            alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                                            className={'h-1-5 ' + (props?.agentAllDetails?.agentDetails?.MYROLEID === 1 ? '' : ' cursor-pointer')}
                                            data-bs-toggle={props?.agentAllDetails?.agentDetails?.MYROLEID === 1 ? undefined : "modal"}
                                            data-bs-target={props?.agentAllDetails?.agentDetails?.MYROLEID === 1 ? undefined : "#removeDownloadedAgentConfirmModal"}
                                        />
                                    </TooltipComponent>
                                    {TranslationEditFlag &&
                                        <>
                                        <EditTranslationIcon translationKey="remove.text" translationType="text"/>
                                        <EditTranslationIcon translationKey={selectedPromptDetails?.MYROLEID === 1 ? "authorRemoveAgentFromLibrary.tooltip" : "userRemoveAgentFromLibrary.tooltip"} translationType="tooltip"/>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </>
            )}
            </div>
        </Card>
        <PromptAdditionalInfoModal
            id="prompt_name_help"
            title={selectedPromptDetails?.PROMPTNAME || ''}
            description={selectedPromptDetails?.PROMPTDESCRIPTION || ''}
            isBluePrompt={selectedPromptDetails?.BLUEPROMPT === 1 || false}
            isOpenSourcePrompt={selectedPromptDetails?.OPENSOURCE === 1 || false}
        />
        <WelcomeNonLoginCard id="welcomeNonLoginCardModal" 
            showWelcomeCard={!isLoggedIn} 
            referalKey={selectedPromptDetails?.OWNERREFERRALID}
            promptAuthorName={selectedPromptDetails?.OWNERFIRSTNAME ? selectedPromptDetails?.OWNERFIRSTNAME +' '+ selectedPromptDetails?.OWNERLASTNAME : ''}
        />
        <ConfirmationPopup id="agentExecutingConfirmModal" 
            title="agentExecution.modal.title"
            owner={selectedPromptDetails?.OWNERFIRSTNAME + " " + selectedPromptDetails?.OWNERLASTNAME}
            ccUsed={averageCCUsed}
            content={
                averageCCUsed === 0 || averageCCUsed === -1 
                ? 
                    'ccNotAvailableAgentExecutionConfirmation.modal.content' 
                : 
                    'ccAvailableAgentExecutionConfirmation.modal.content'
            } 
            onConfirm={handleConfirmPromptExecution} 
            setDoNotAsk={setDontShowExeAlert} 
        />
        <ConfirmationPopup id="removeDownloadedAgentConfirmModal" 
            title="removeAgentConfirmation.modal.title" 
            content="removeAgentConfirmation.modal.content"
            onConfirm={handleConfirmDeleteDownloadedAgent}
        />
        </>
    );
};

export default AssetExecution;