import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import { Tooltip } from 'bootstrap';
import { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { getTranslatedText } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';



interface PromptExecutionStepperProps {
    activeStep: number
    steps: any
    progressBarData: any
}

const StepperConnectorStyles = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
        backgroundColor: "var(--bs-secondary)", // Change this color as needed for the active step
        },
    },
    completed: {
        "& $line": {
            backgroundColor: "var(--bs-success)", // Change this color as needed for completed steps
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "var(--bs-gray-600)", // Color for unfinished steps
        borderRadius: 1,
    },
})(StepConnector);

const PromptExecutionStepper: React.FC<PromptExecutionStepperProps> = (props) => {
    const PageTranslation = usePageTranslation();

    useEffect(() => {
        // Initialize Bootstrap tooltips
        const tooltipTriggerList = Array.from(document.querySelectorAll('.step-label'));
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new Tooltip(tooltipTriggerEl, {
                title: tooltipTriggerEl.getAttribute('data-tooltip') || '',
                placement: 'top',
                trigger: 'hover',
                html: true,
            });
        });
    
        return () => {
            // Dispose tooltips on component unmount
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                const tooltip = Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) tooltip.dispose();
            });
        };
    }, [props.progressBarData,props.steps]);

    return (
    <div className="px-2 overflow-auto d-flex justify-content-center w-100 overflow-auto bc-horizontal-scroll">
        <div className="d-flex flex-grow-1 flex-shrink-0 w-100">
            <Stepper 
                activeStep={props.activeStep} 
                alternativeLabel 
                connector={<StepperConnectorStyles />} 
                className='w-100 mb-3'
            >
                {props.steps.map((step: any, index: number) => (
                    <Step key={index} completed={index < props.activeStep}>
                        <StepLabel
                            StepIconComponent={() => (
                                <div 
                                    className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
                                    style={{
                                        border: index < props.activeStep 
                                        ? '2px solid var(--bs-success)'
                                        : index === props.activeStep 
                                        ? '2px solid var(--bs-secondary)' 
                                        : '2px solid var(--bs-gray-600)',
                                        width: '40px',
                                        height: '40px'
                                    }}
                                >   
                                    {step.image ? (
                                        <img 
                                            src={step.image} 
                                            alt={getTranslatedText('imageAlt.text', PageTranslation)}
                                            className='h-2-2 rounded-circle object-fit-fill'
                                        />
                                    ) : (
                                        <span>{step.label}</span> 
                                    )}
                                </div>
                            )}
                            className="step-label"
                            data-tooltip={step.tooltip}
                        />
                    </Step>
                ))}
            </Stepper>
        </div>
    </div>
    );
};

export default PromptExecutionStepper;